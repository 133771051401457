@import "src/themes/daikiri/styles/index.scss";
.Input {
  &__wrapper {
    position: relative;
  }

  &__element {
    display: block;
    width: 100%;
    padding: space(0.75);
    border: 1px solid getvar($colors, "neutral", "300");
    border-radius: space(0.25);
    font-size: 14px;
    transition: ease-in-out 0.2s border;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    &:hover {
      border: 1px solid getvar($colors, "neutral", "500");
    }

    &:active {
      border: 1px solid getvar($colors, "primary", "500");
    }

    &:focus {
      outline: none;
      border: 1px solid getvar($colors, "primary", "500");
    }
  }

  &__error-message {
    display: flex;
    margin-top: space(0.25);
    margin-bottom: space(0.5);
    align-items: flex-start;

    svg {
      color: getvar($colors, "error", "600");
      margin-right: space(0.25);
      min-height: 14px;
      min-width: 14px;
      margin-top: 2px;
    }
  }

  &--error {
    .Input__error-message {
      text-align: left;
      color: getvar($colors, "error", "600");
    }
    .Input__element {
      border: 1px solid getvar($colors, "error", "500");
      padding: space(0.75) space(2) space(0.75) space(0.75);
    }
  }
}
